const distances = {
  pageMarginBig: 50,
  pageMarginSmall: 15,
  gap: 20,
  sectionMarginBig: 150,
  sectionMarginSmall: 100,
  topMargin: 160,
  topMarginMobile: 100,
};

export default distances;
