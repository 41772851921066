import React from "react";
import styled, { keyframes } from "styled-components";

const leftRightAnimation = keyframes`
 0% { width: 0%; }
 100% { width: 100%; }
`;

const Wrapper = styled.hr`
  display: block;
  border: 0;
  opacity: 1;
  border-top: 1rem solid
    ${(props) => (props.color ? props.color : "var(--rest)")};
  padding: 0;
  z-index: 102;
  pointer-events: none;
  width: ${(props) => (props.width ? props.width : "100%")};
  &.animate {
    width: 0%;
    animation-name: ${leftRightAnimation};
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  &.delay {
    animation-delay: 0.5s !important;
  }
`;

const Line = ({ color, width, className }) => (
  <Wrapper color={color} width={width} className={className} />
);

export default Line;
