const typographySizes = {
  ss: 10,
  ssh: 1.4,
  s: 14,
  sh: 1.4,
  sm: 24,
  smh: 1.1,
  m: 38,
  mh: 1.1,
  l: 48,
  lh: 1.1,
  xl: 127,
  xlh: 1,
};

export default typographySizes;

// const typographySizes = {
//   s: 14,
//   sh: 1.6,
//   sm: 22,
//   smh: 1.2,
//   m: 30,
//   mh: 1.2,
//   l: 38,
//   lh: 1.1,
//   xl: 127,
//   xlh: 1,
// };

// export default typographySizes;
