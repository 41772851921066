exports.components = {
  "component---src-layouts-about-js": () => import("./../../../src/layouts/about.js" /* webpackChunkName: "component---src-layouts-about-js" */),
  "component---src-layouts-contact-js": () => import("./../../../src/layouts/contact.js" /* webpackChunkName: "component---src-layouts-contact-js" */),
  "component---src-layouts-form-js": () => import("./../../../src/layouts/form.js" /* webpackChunkName: "component---src-layouts-form-js" */),
  "component---src-layouts-home-js": () => import("./../../../src/layouts/home.js" /* webpackChunkName: "component---src-layouts-home-js" */),
  "component---src-layouts-news-js": () => import("./../../../src/layouts/news.js" /* webpackChunkName: "component---src-layouts-news-js" */),
  "component---src-layouts-post-js": () => import("./../../../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-layouts-project-js": () => import("./../../../src/layouts/project.js" /* webpackChunkName: "component---src-layouts-project-js" */),
  "component---src-layouts-projects-js": () => import("./../../../src/layouts/projects.js" /* webpackChunkName: "component---src-layouts-projects-js" */),
  "component---src-layouts-shop-js": () => import("./../../../src/layouts/shop.js" /* webpackChunkName: "component---src-layouts-shop-js" */),
  "component---src-layouts-shop-product-js": () => import("./../../../src/layouts/shopProduct.js" /* webpackChunkName: "component---src-layouts-shop-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

