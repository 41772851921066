const mediaQuery = {
  mobile: "480px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1200px",
  desktopBig: "1400px",
  big: "1600px",
};

export default mediaQuery;
