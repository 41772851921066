import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const MainWrapper = styled.div`
  padding: 0 ${distances.pageMarginBig}rem;
  width: calc(100% - ${distances.pageMarginBig * 2}rem);
  position: relative;
  height: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0 15rem;
    width: calc(100% - 30rem);
  }
`;

export default MainWrapper;
