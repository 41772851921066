import React from "react";
import { Link } from "gatsby";
import MainWrapper from "src/components/global/MainWrapper.js";
import styled from "styled-components";
import main from "src/assets/styles/main.js";
import Line from "src/components/global/Line.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Arrow from "src/assets/svg/arrow.inline.svg";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import slugify from "slugify";

const Wrapper = styled.div``;
const StyledArrow = styled(Arrow)`
  width: 28rem;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :first-of-type {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -40rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: absolute;
    right: 0;
  }
`;
const FooterWrapper = styled.footer`
  background-color: ${(props) =>
    props.colorBg ? props.colorBg : "var(--rest)"};
  border-radius: ${main.radius}rem;
  padding: 50rem;
  a,
  h3,
  p,
  span {
    color: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};
  }
  hr {
    border-top: 1rem solid
      ${(props) => (props.colorText ? props.colorText : "var(--bg)")} !important;
  }
  ${StyledArrow} {
    #arr {
      fill: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    padding: 15rem;
    .midLine {
      display: none;
    }
  }
`;

const TopWrapper = styled.div`
  display: flex;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const Title = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--bg);
  width: 37.5%;
  padding-right: 18.75%;
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(56.25% - 20rem);
    padding-right: 20rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding-right: 0rem;
    margin-bottom: 20rem;
  }
`;

const TopRight = styled.div``;

const TopContactLine = styled.p`
  font-size: ${typographySizes.s}rem;
  line-height: ${typographySizes.sh};
  a {
    text-decoration: none;
    font-size: inherit;
  }
`;

const BottomWrapper = styled.div`
  margin-top: 50rem;
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const BottomTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.desktop}) {
    flex-direction: column;
  }
`;

const BottomLeft = styled.div`
  width: 37.5%;
  padding-right: 18.75%;

  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(56.25% - 20rem);
    padding-right: 20rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding-right: 0rem;
    margin-bottom: 50rem;
  }
`;

const BottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const AdressWrapper = styled.p`
  font-size: ${typographySizes.s}rem;
  line-height: ${typographySizes.sh};
`;

const Map = styled.a`
  margin-top: 10rem;
  display: inline-block;
  text-decoration: none;
  font-size: inherit;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    font-size: ${typographySizes.s}rem;
    line-height: ${typographySizes.sh};
  }
  @media (max-width: ${mediaQuery.desktop}) {
    margin-top: 50rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
  }
`;

const SocialLink = styled.a`
  display: inline-block;
  width: 50%;
  text-decoration: none;
`;

const Wetrzech = styled.span`
  font-size: calc((100vw - 200rem) * 0.095);
  position: relative;
  top: 2.8vw;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: calc((100vw - 80rem) * 0.215);
    margin-top: 30rem;
  }
`;

const LinkInner = styled.div`
  padding: 20rem 0;
  font-size: ${typographySizes.sm}rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  span {
    font-size: inherit;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`;
 
const LinkWrapper = styled(Link)`
  margin-top: -1rem;
  display: block;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  &:first-of-type {
    margin-top: 0;
  }
  span {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  :hover {
    ${StyledArrow} {
      transform: translateX(28rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      :first-of-type {
        transform: translate(40rem, -50%);
      }
    }
    span {
      transform: translateX(50rem);
      transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const FooterLink = ({ name, link, colorText }) => (
  <LinkWrapper to={`/${link}`}>
    <Line color={colorText} />
    <LinkInner>
      <StyledArrow />
      <span> {name}</span>
      <StyledArrow />
    </LinkInner>
    <Line color={colorText} />
  </LinkWrapper>
);

const Footer = ({
  texts,
  contactLink,
  aboutPage,
  worksPage,
  newsPage,
  contactPage,
  colorBg,
  colorText,
  lang,
}) => {
  const aboutLink = slugify(aboutPage, {
    lower: true,
    strict: true,
  });
  const workLink = slugify(worksPage, {
    lower: true,
    strict: true,
  });
  const newsLink = slugify(newsPage, {
    lower: true,
    strict: true,
  });
  const contactLinkLink = slugify(contactPage, {
    lower: true,
    strict: true,
  });
  return (
    <Wrapper>
      <MainWrapper>
        <FooterWrapper colorBg={colorBg} colorText={colorText}>
          <TopWrapper>
            <Title>{texts.Top_text}</Title>
            <TopRight>
              <TopContactLine>
                {texts.Mail_text}{" "}
                <a href={`mailto:${contactLink.Main_mail}`}>
                  {contactLink.Main_mail}
                </a>{" "}
              </TopContactLine>
              <TopContactLine>
                {texts.Tel_text}{" "}
                <a href={`tel:${contactLink.Main_tel}`}>
                  {contactLink.Main_tel}
                </a>{" "}
              </TopContactLine>
            </TopRight>
          </TopWrapper>
          <div className="midLine">
            <Line color={colorText} />
          </div>

          <BottomWrapper>
            <BottomLeft>
              <FooterLink
                colorText={colorText}
                name={aboutPage}
                link={lang === "en" ? `en/${aboutLink}` : aboutLink}
              />
              <FooterLink
                colorText={colorText}
                name={worksPage}
                link={lang === "en" ? `en/${workLink}` : workLink}
              />
              <FooterLink
                colorText={colorText}
                name={newsPage}
                link={lang === "en" ? `en/${newsLink}` : newsLink}
              />
              <FooterLink
                colorText={colorText}
                name={contactPage}
                link={lang === "en" ? `en/${contactLinkLink}` : contactLinkLink}
              />
            </BottomLeft>
            <BottomRight>
              <BottomTopWrapper>
                <AdressWrapper>
                  {contactLink.Street} <br /> {contactLink.Zip_code}{" "}
                  {contactLink.City} <br />
                  <Map href={contactLink.Map_link} target="_blank">
                    {contactLink.Map_text}
                  </Map>
                </AdressWrapper>
                <SocialWrapper>
                  {contactLink.Social_link.map((LinkInfo) => (
                    <SocialLink href={LinkInfo.Link} target="_blank">
                      {LinkInfo.Name}
                    </SocialLink>
                  ))}
                </SocialWrapper>
              </BottomTopWrapper>
              <Wetrzech>we_trzech</Wetrzech>
            </BottomRight>
          </BottomWrapper>
        </FooterWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

export default Footer;
