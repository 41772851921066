import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`

  /* @font-face {
    font-family: 'TTFirsNeue-Regular';
    src: url("/fonts/font.woff") format("woff");
  } */

    *{
        margin: 0;
        padding: 0;
        font-family: 'TTFirsNeue-Regular', sans-serif;
        color: var(--bg);
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
        border: none;
       
        @media (min-width: 1440px) {
            /* font-size: 0.0695vw; */

            }
    }
    
    /* cookieyes */
    button.cky-show-desc-btn:not(:hover):not(:active), .cky-always-active{
        color: #f5f5f5 !important;
    }
    .cky-audit-table{
        background-color: #0a0a0a !important;
        color: #f5f5f5 !important;
        border: solid #f5f5f5 !important;
    }
    .cky-preference-center{
        border-color: #f5f5f5 !important;
    }
    .cky-notice-des {
    line-height: 1.4 !important;
}
ul.cky-cookie-des-table li{
    color: #f5f5f5 !important;
    line-height: 1.4 !important;
    font-size: 12rem !important;
}

    /* html{scroll-behavior: smooth;} */
    ::selection {
        color: var(--rest);
        background: var(--bg);
;
}

div{
    font-size: inherit;
    color: inherit;
    /* line-height: inherit !important; */
}

html {width: 100%; } /* Force 'html' to be viewport width */

 body{
     overflow-x: hidden;
     width: 100vw;
     font-size:  16rem ; 
     --bg: #0A0A0A;
     --rest: #f5f5f5; 
     background-color: var(--bg);
    

 }
 img {
    display: none;
}


img[src] {
   display: block;
 }

body.dark{
    --bg: #0A0A0A;
     --rest: #f5f5f5; 
     background-color: var(--bg);
}

 body.white {
    --bg: #f5f5f5; 
    --rest: #0A0A0A;
    background-color: var(--bg);
 }
 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
