import React, { useEffect } from "react";
import { ContextProviderComponent } from "src/layouts/Context.js";

import GlobalStyle from "src/assets/styles/globalStyles.js";
import Footer from "src/components/global/Footer.js";

import Cookies from "src/components/global/Cookies.js";

const HomePage = ({ data, children, pageContext }) => {
  useEffect(() => {
    const lettersToReplace = [
      "a",
      "i",
      "o",
      "u",
      "w",
      "z",
      "A",
      "I",
      "O",
      "U",
      "W",
      "Z",
    ];
    const arrayLength = lettersToReplace.length;
    const elements = document.querySelectorAll(
      " h2:not(.intro-text), h3:not(.letter), h4:not(.letter), h5:not(.letter), h6:not(.letter), p:not(.intro-text)"
    );

    elements.forEach((element) => {
      let textReplace = element.innerHTML;

      for (let i = 0; i < arrayLength; i++) {
        const textSplit = textReplace.split(` ${lettersToReplace[i]} `);
        textReplace = textSplit.join(` ${lettersToReplace[i]}&nbsp;`);
      }

      element.innerHTML = textReplace;
    });
  }, [{}]);
  return (
    <>
      {/* <Cookies /> */}
      <ContextProviderComponent>
        <GlobalStyle />
        {children}
      </ContextProviderComponent>
    </>
  );
};

export default HomePage;
